enum ChoreStatus {
  unset,
  processing= 'processing',
  done = 'done',
}

interface RoadChore {
  content: string;
  status: ChoreStatus
}

class Roadmap {
  date: string;

  choreList?: Array<RoadChore>;

  public constructor(date:string, choreList:Array<RoadChore> = []) {
    this.date = date;
    this.choreList = choreList;
  }
}

const roadmapList = [
  new Roadmap('road01Title', [
    { content: 'road01Chore01', status: ChoreStatus.done },
    { content: 'road01Chore02', status: ChoreStatus.done },
    { content: 'road01Chore03', status: ChoreStatus.done },
    { content: 'road01Chore04', status: ChoreStatus.done },
    { content: 'road01Chore05', status: ChoreStatus.done },
    { content: 'road01Chore06', status: ChoreStatus.done },
  ]),
  new Roadmap('road02Title', [
    { content: 'road02Chore01', status: ChoreStatus.done },
    { content: 'road02Chore02', status: ChoreStatus.done },
    { content: 'road02Chore03', status: ChoreStatus.processing },
    { content: 'road02Chore04', status: ChoreStatus.processing },
    { content: 'road02Chore05', status: ChoreStatus.unset },
    { content: 'road02Chore06', status: ChoreStatus.unset },
    { content: 'road02Chore07', status: ChoreStatus.done },
    { content: 'road02Chore08', status: ChoreStatus.unset },
    { content: 'road02Chore09', status: ChoreStatus.unset },
  ]),
  new Roadmap('road03Title', [
    { content: 'road03Chore01', status: ChoreStatus.unset },
    { content: 'road03Chore02', status: ChoreStatus.unset },
    { content: 'road03Chore03', status: ChoreStatus.unset },
    { content: 'road03Chore04', status: ChoreStatus.unset },
    { content: 'road03Chore05', status: ChoreStatus.unset },
  ]),
  new Roadmap('road04Title', [
    { content: 'road04Chore01', status: ChoreStatus.unset },
    { content: 'road04Chore02', status: ChoreStatus.unset },
    { content: 'road04Chore03', status: ChoreStatus.unset },
    { content: 'road04Chore04', status: ChoreStatus.unset },
  ]),
  new Roadmap('road05Title', [
    { content: 'road05Chore01', status: ChoreStatus.unset },
    { content: 'road05Chore02', status: ChoreStatus.unset },
  ]),
  new Roadmap('road06Title', [
    // { content: 'road06Chore01', status: ChoreStatus.unset },
  ]),
];

export {
  ChoreStatus,
  RoadChore,
  Roadmap,
  roadmapList,
};
