export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap"])},
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
        "road01Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q1 2022"])},
        "road01Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve and initiate this project"])},
        "road01Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Website"])},
        "road01Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Economic Model"])},
        "road01Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop & Launch Website"])},
        "road01Chore05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO Token Fair Launch (PancakeSwap)"])},
        "road01Chore06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch NECO Farming System"])},
        "road02Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q2 2022"])},
        "road02Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Website"])},
        "road02Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO Token Airdrop"])},
        "road02Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Neco Fishing Game Design"])},
        "road02Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing Game Development"])},
        "road02Chore05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake NECO for NFISH Airdrop"])},
        "road02Chore06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch NFISH Farming System"])},
        "road02Chore07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staking for NFTs"])},
        "road02Chore08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch NFT Marketplace"])},
        "road02Chore09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Neco Fishing Beta Version"])},
        "road03Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q3 2022"])},
        "road03Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Bugs fix"])},
        "road03Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Neco Fishing Official Version"])},
        "road03Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Update for Neco Fishing"])},
        "road03Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Earning Distribution System"])},
        "road03Chore05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Vote & Governance System"])},
        "road04Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q4 2022"])},
        "road04Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing update"])},
        "road04Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch NECO Gachapon"])},
        "road04Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start New Games (Neco Land) Design"])},
        "road04Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Partnerships"])},
        "road05Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q1 2023"])},
        "road05Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Neco Land Beta Version"])},
        "road05Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Game Details"])},
        "road06Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])}
      },
      "zh": {
        "roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["路线图"])},
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
        "road01Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年Q1"])},
        "road01Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目立项"])},
        "road01Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站设计"])},
        "road01Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经济模型设计"])},
        "road01Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站开发并上线"])},
        "road01Chore05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO代币公平发射（PancakeSwap）"])},
        "road01Chore06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO代币质押挖矿上线"])},
        "road02Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年Q2"])},
        "road02Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["官网再设计"])},
        "road02Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO代币空投"])},
        "road02Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing游戏策划完毕"])},
        "road02Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing游戏开发"])},
        "road02Chore05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押NECO获取NFISH空投"])},
        "road02Chore06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH代币质押挖矿"])},
        "road02Chore07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押获取NFT道具"])},
        "road02Chore08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上线NFT交易市场"])},
        "road02Chore09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing测试版上线"])},
        "road03Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年Q3"])},
        "road03Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏bug修复"])},
        "road03Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing正式版上线"])},
        "road03Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing新内容更新"])},
        "road03Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收益分发系统上线"])},
        "road03Chore05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投票治理系统上线"])},
        "road04Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年Q4"])},
        "road04Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing新内容更新"])},
        "road04Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO扭蛋机启动"])},
        "road04Chore03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新游戏Neco Land策划启动"])},
        "road04Chore04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多的Partnership"])},
        "road05Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023年Q1"])},
        "road05Chore01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Land游戏测试版上上线"])},
        "road05Chore02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多游戏细节"])},
        "road06Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])}
      }
    }
  })
}
